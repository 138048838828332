/* General Flipbook styles */
.desktop-pdf,
.mobile-pdf {
  width: 100%;
  height: auto; /* 100% height of viewport */
  overflow: hidden;
}

/* Rotate message styles */
.rotate-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-size: 1.5em;
  color: #555;
  padding: 20px;
  box-sizing: border-box;
}

/* Fullscreen behavior for desktop and landscape mobile */
@media screen and (min-width: 768px) {
  .desktop-pdf {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}

/* Mobile Portrait - Show rotate message */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .rotate-message {
    font-size: 1.2em;
    color: #333;
    padding: 20px;
    box-sizing: border-box;
  }
}

/* Mobile Landscape - Fullscreen Flipbook */
@media screen and (max-width: 768px) and (orientation: landscape) {
  .mobile-pdf {
    width: 100vw;
    height: 100vh;
  }
}
